<template>
  <div class="admin-wrap">
    <AdminMenu active-item="users" />
    <div class="admin-main">
      <AdminHeader title="帳號管理" backlink="javascript:history.back()" />
       <div class="account-detail">
        <div class="account-detail__basic-info">
          <div class="account-detail__basic-info-inner">
            <div class="account-detail__col">
              <h3>{{title}}</h3>
              <div class="account-detail__form">
                <div class="account-detail__row">
                  <label for="username">帳號</label>
                  <input type="text" id="username" :disabled="userId > 0" v-model="user.username">
                </div>
                <div class="account-detail__row">
                  <label for="password">{{passwordLabel}}</label>
                  <input type="password" id="password" v-model="user.password">
                </div>
                <div class="account-detail__row">
                  <label for="type">角色設定</label>
                  <div class="account-detail__select-wrap">
                    <select name="type" id="type" v-model="selectedRoleId">
                      <option value="0">- 請選擇 -</option>
                      <option v-for="role in roles" :key="role" :value="role.id">{{role.description}}</option>
                    </select>
                  </div>
                </div>
                <div class="account-detail__row">
                  <label for="type">員工</label>
                  <div class="account-detail__select-wrap">
                    <select name="type" id="type" v-model="this.user.staffId">
                      <option v-for="staff in staffs" :key="staff" :value="staff.id">{{staff.fullName}}</option>
                    </select>
                  </div>
                </div>
                <div class="account-detail__row">
                  <label for="fullname">姓名</label>
                  <input type="text" id="fullname" v-model="user.fullName">
                </div>
                <div class="account-detail__row">
                  <label for="email">E-Mail</label>
                  <input type="text" id="email" v-model="user.email">
                </div>
                <div class="account-detail__row">
                  <label for="type">狀態</label>
                  <div class="account-detail__select-wrap">
                    <select name="type" id="type" v-model="user.status">
                      <option value="0">停用</option>
                      <option value="1">啟用</option>
                      <option value="-2">密碼鎖住</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button class="admin-btn-lg" @click="onSave">儲存</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  apiGetUser,
  apiCreateUser,
  apiUpdateUser,
  apiGetRoles,
  apiGetStaffs
} from '@/api/v1/admin'
import AdminMenu from '../../components/AdminMenu'
import AdminHeader from '../../components/AdminHeader'

export default {
  name: 'UserDetail',
  props: ['userId'],
  components: { AdminMenu, AdminHeader },
  computed: {
    title () {
      return this.userId > 0 ? '基本資料' : '基本資料（新增）'
    },
    passwordLabel () {
      return this.userId > 0 ? '變更密碼' : '設定密碼'
    }
  },
  created () {
    this.fetchData()
  },
  data () {
    return {
      user: {
        id: null,
        username: '',
        password: '', // only for update / create, won't be returned by server
        fullName: '',
        email: '',
        status: 1,
        staffId: 0,
        roles: []
      },
      roles: [],
      staffs: [],
      selectedRoleId: 0
    }
  },
  watch: {
    selectedRoleId (newVal) {
      this.user.roles[0] = this.roles.find(r => r.id === newVal)
    }
  },
  methods: {
    fetchData () {
      apiGetStaffs().then(data => {
        if (!data.error) {
          this.staffs = data.content
        }
      })
      apiGetRoles().then(data => {
        if (!data.error) {
          this.roles = data
        }
      })
      if (this.userId > 0) {
        apiGetUser(this.userId).then(data => {
          console.log(data)
          if (data.error) {
            console.log(data.error.message)
          } else {
            this.user = data
            this.selectedRoleId = this.user.roles[0].id
          }
        })
      }
    },
    onSave () {
      const api = this.userId > 0 ? apiUpdateUser : apiCreateUser
      const params = JSON.parse(JSON.stringify(this.user))
      if (!this.checkInput()) {
        return
      }
      api(params).then(data => {
        console.log(data)
        if (data.error) {
          this.$swal('儲存失敗', data.message, 'warning')
        } else {
          this.$swal('儲存完成', '', 'success').then(() => history.back())
        }
      }).catch(err => {
        this.$swal('儲存失敗', err.data.error, 'warning')
      })
    },
    checkInput () {
      let passwordOk = true
      if (this.userId > 0) {
        if (this.user.password && (this.user.password.length > 0) && (this.user.password.length < 6 || this.user.password.length > 12)) {
          passwordOk = false
        }
      } else {
        if (this.user.password.length < 6 || this.user.password.length > 12) {
          passwordOk = false
        }
      }
      if (!passwordOk) {
        this.$swal('密碼格式不正確', '密碼為6~12位', 'warning')
        return false
      }
      if (this.selectedRoleId === 0) {
        this.$swal('請選擇角色', '請選擇此帳號配置的角色', 'warning')
        return false
      } else {
        this.user.roles[0] = this.roles.find(r => r.id === this.selectedRoleId)
      }
      return true
    }
  }
}
</script>

<style>

</style>
